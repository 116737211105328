import React, { useState, useCallback } from "react"
import { Flexbox, Box, Text, Heading } from "@ballchy/styled-bits"
import { Overlay } from "atoms"
import styled, { keyframes } from "styled-components"
import Footer from "components/Footer"
import { useStore } from "store/provider"

const LandingPageContainer = () => {
  const { mode } = useStore()
  const [role, setRole] = useState("full")
  const [showAnimation, setShowAnimation] = useState(false)

  const handleHover = useCallback(e => {
    if (e.currentTarget.getAttribute("data-role")) {
      const role = e.currentTarget.getAttribute("data-role")
      setRole(role)
      setShowAnimation(role === "front")
    }
  }, [])

  const resetHover = useCallback(() => {
    setRole("full")
    setShowAnimation(false)
  }, [setShowAnimation])

  return (
    <>
      <Overlay
        id="landing-page-overlay"
        fontSize={["270px", "270px", "520px"]}
        textAlign="center"
        textTransform="uppercase"
      >
        Chi
        {mode === "dark" && (
          <RightHat as="img" src="/sleeping_hat.svg" alt="sleeping_hat" />
        )}
      </Overlay>
      <StreetCarAnimationContainer
        display={["none", "none", "inherit"]}
        overflow="hidden"
      >
        <StreetCarSVG
          width={["150px", "400px", "400px"]}
          as="img"
          display="block"
          src="/streetcar.svg"
          alt="streetcar"
        />
      </StreetCarAnimationContainer>
      <Flexbox
        as="section"
        id="landing-page-container"
        position="relative"
        flexDirection="column"
        minHeight={["auto", "auto", "100vh"]}
        maxWidth="1040px"
        mx="auto"
        px={3}
        py={[3, 3, 4]}
      >
        <Flexbox
          id="landing-page-split-screen"
          flex={["0 1 auto", "0 1 auto", 1]}
          flexDirection={["column-reverse", "column-reverse", "row"]}
          position="relative"
          overflow="hidden"
        >
          <Flexbox
            id="landing-page-text-container"
            flexDirection="column"
            justifyContent={["flex-start", "flex-start", "center"]}
            flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
            maxWidth={["100%", "100%", "50%"]}
            width={[1, 1, 1 / 2]}
            pr={[0, 0, 2]}
          >
            <Box className="landing-page-text-heading" mb="2">
              <Text fontSize={[4, 4, 5]} textTransform="uppercase">
                /&nbsp;
                <Text fontSize={[3, 4, 4]} fontWeight="bold">
                  Full
                </Text>
                ·
                <Text fontSize={[3, 4, 4]}>
                  stack&nbsp;&nbsp;Developer&nbsp;
                </Text>
                /
              </Text>
            </Box>
            <Flexbox id="landing-page-text-body" mb="2" overflow="hidden">
              <IntroTexts
                flexDirection="column"
                flex="1 0 100%"
                maxWidth="100%"
                cat="full"
                role={role}
              >
                <Heading mb={2}>Passion</Heading>
                <Text as="p">
                  Hello, my name is Marco Yeung. I am a full-stack developer
                  based in Toronto, Canada{" "}
                  <span role="img" aria-label="flag">
                    🇨🇦
                  </span>
                  . My passion is simple - coding, coffee and cycling. I am a
                  senior at the University of Toronto majoring Computer Science.
                  Currently, I am looking for a new career opportunity when I
                  graduate in April.
                </Text>
              </IntroTexts>
              <IntroTexts
                flexDirection="column"
                flex="1 0 100%"
                cat="front"
                role={role}
              >
                <Heading mb={2}>Frontend Experience</Heading>
                <Text as="p">
                  I have been coding with React.js to create reusable UI
                  components for about 3 years now. In my free time, I like to
                  try out different Javascript frontend framework libraries. I
                  have also tried Vue.js and Angular.js, but React.js is still
                  my favourite framework. Lately, JAMStack has exploded in
                  popularity. In particular, Gatsby.js has caught my attention,
                  and it has been my go-to for creating static landing pages and
                  blogs.
                </Text>
              </IntroTexts>
              <IntroTexts
                flexDirection="column"
                flex="1 0 100%"
                cat="back"
                role={role}
              >
                <Heading mb={2}>Backend Experience</Heading>
                <Text as="p">
                  I have used CodeIgniter and Express.js in my previous works. I
                  have experience developing in a distrubuted system like AWS.
                  Somedays, I would still like to try out Go and Ruby on Rails.
                  Nowsdays, I use AWS Lambda + NodeJS for most of my websites.
                </Text>
              </IntroTexts>
            </Flexbox>
          </Flexbox>
          <Flexbox
            ic="landing-page-image-caontainer"
            flexDirection="column"
            justifyContent="center"
            flex={["1 0 100%", "1 0 100%", "1 0 50%"]}
            maxWidth={["100%", "100%", "50%"]}
            pl={[0, 0, 2]}
            pt={[4, 4, 0]}
            pb={[2, 2, 0]}
          >
            <StackWrapper
              position="relative"
              height={["100%", "50%", "75%"]}
              py={[3, 3, 3]}
              flexDirection="column"
              justifyContent={["flex-start", "flex-start", "center"]}
            >
              <FrontStack
                as="a"
                href="#frontend"
                width={["250px", "300px", "400px"]}
                mx="auto"
                zIndex="250"
              >
                <Box
                  onMouseEnter={handleHover}
                  onMouseLeave={resetHover}
                  as="img"
                  display="block"
                  data-role="front"
                  src={
                    showAnimation ? "/ipad_top_animated.svg" : "/ipad_top.svg"
                  }
                  alt="ipad_top"
                />
              </FrontStack>
              <MidStack
                as="a"
                href="#intro"
                width={["250px", "300px", "400px"]}
                mx="auto"
                onMouseEnter={handleHover}
                onMouseLeave={resetHover}
                zIndex="100"
              >
                <Box
                  as="img"
                  display="block"
                  src="/ipad_middle.svg"
                  data-role="full"
                  alt="ipad_middle"
                  onMouseEnter={handleHover}
                  onMouseLeave={resetHover}
                />
              </MidStack>
              <BackStack
                as="a"
                href="#backend"
                width={["250px", "300px", "400px"]}
                mx="auto"
                zIndex="200"
              >
                <Box
                  as="img"
                  display="block"
                  src="/ipad_bottom.svg"
                  alt="ipad_bottom"
                  data-role="back"
                  onMouseEnter={handleHover}
                  onMouseLeave={resetHover}
                />
              </BackStack>
            </StackWrapper>
          </Flexbox>
        </Flexbox>
        <Footer />
      </Flexbox>
    </>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const move = keyframes`
    0%, 10%, 100% {
      transform: translateX(-100%);
    }
    50%, 60% {
      transform: translateX(140%);
    }
`

const StreetCarAnimationContainer = styled(Box)`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -200;
`

const StreetCarSVG = styled(Box)`
  animation-name: ${move};
  animation-duration: 40000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

const IntroTexts = styled(Flexbox)`
  display: ${({ role, cat }) => (cat === role ? "defalut" : "none")};
`

const RightHat = styled(Box)`
  position: absolute;
  top: 0;
  right: -3%;
  transform: rotate(34deg) !important;
  width: 100px;
  z-index: 0 !important;
  height: 100px;
  transform: translate(-50%, -50%);
  z-index: -100;
  color: ${({ theme }) => theme.colors.overlay};
`

const StackWrapper = styled(Flexbox)`
  animation: ${fadeIn} 1s ease-out;
  animation-fill-mode: backwards;
`

const Stack = styled(Box)`
  transition: all 0.4s ease-out;
  position: relative;
`

const FrontStack = styled(Stack)`
  /* Original transformation */
  transform: translate(0%, 105%);

  ${StackWrapper}:hover & {
    transform: translate(0%, 0%);

    &:hover,
    &:active & {
      transform: translate(0%, 0%) scale(1.4);
    }
  }
`

const MidStack = styled(Stack)`
  :hover,
  :active {
    transform: scale(1.2);
  }
`

const BackStack = styled(Stack)`
  position: relative;

  /* Original transformation */
  transform: translate(0%, -87%);

  ${StackWrapper}:hover & {
    transform: translate(0%, 0%);

    &:hover,
    &:active {
      transform: translate(0%, 0%) scale(1.2);
    }
  }
`

export default LandingPageContainer
