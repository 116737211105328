import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import LandingPageConatiner from "container"

const IndexPage = () => (
  <Layout>
    <SEO title="Full-stack Developer" />
    <LandingPageConatiner/>
  </Layout>
)

export default IndexPage
